<template>
    <slot v-if="$slots.default" :wrapper="wrapper"></slot>
    <ODatePicker v-else-if="editorItem.editor === 'date'" v-bind="$attrs" v-model="computedValue" date format="Short Date"/>
    <ODatePicker v-else-if="editorItem.editor === 'datetime'" v-bind="$attrs" v-model="computedValue" format="General Date Short Time" timepicker/>
    <input v-else-if="editorItem.editor === 'bit'" v-bind="$attrs" type="checkbox" :checked="computedValue" @change="updateBitValue">
    <input v-else-if="editorItem.editor === 'number'" v-bind="$attrs" type="number" v-model="computedValue">
    <input v-else-if="editorItem.editor === 'text'" v-bind="$attrs" type="text" v-model="computedValue">
    <OrgUnitsLookup v-else-if="editorItem.editor === 'orgunit'" :bind="sel => editorItem.lookupBind(sel)">
        <template #orgunit>
            <input type="text" class="lookup-icon" v-bind="$attrs" :placeholder="$t('Select...')" v-model="computedValue"/>
        </template>  
    </OrgUnitsLookup>
    <OObjectsLookup v-else-if="editorItem.editor === 'object'" :bind="sel => editorItem.lookupBind(sel)"
        :objectsWhereClause="editorItem.whereClause">
        <template #target="{target}">
            <input :ref="target" type="text" class="lookup-icon" v-bind="$attrs" :placeholder="$t('Select...')" v-model="computedValue"/>
        </template>  
    </OObjectsLookup>
    <ODataLookup v-else-if="editorItem.editor === 'lookup'" :dataObject="editorItem.dataObject" :value="computedValue" :bind="sel => editorItem.lookupBind(sel)"
        :placeholder="$t('Select...')" :multiselect="editorItem.isMultiselect">
        <OColumn v-for="col in editorItem.columns" :field="col.name" :width="col.size" />
    </ODataLookup>
    <template v-else-if="editorItem.editor === 'local_lookup'">
        <OSelect v-if="editorItem.isMultiselect" class="lookup-icon" v-model="localMultiSelectValue" 
            :placeholder="$t('Select...')" v-bind="$attrs" multiple clearable>
            <option v-for="option in editorItem.lookupValues" :value="option.Value">{{option.Value}}</option>
        </OSelect>
        <OSelect v-else="editorItem.editor === 'local_lookup'" class="lookup-icon" v-model="computedValue" 
            :placeholder="$t('Select...')" v-bind="$attrs" clearable>
            <option v-for="option in editorItem.lookupValues" :value="option.Value">{{option.Value}}</option>
        </OSelect>
    </template>
</template>

<script setup lang="ts">
import type { DataItemModel } from 'o365.modules.DataObject.Types.ts';

import ODatePicker from 'o365.vue.components.DatePicker.vue';
import PropertiesEditor from 'o365.controls.DataGrid.PropertiesEditor.ts';
import { ref, computed } from 'vue';
// import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';

import OrgUnitsLookup from 'o365.vue.components.OrgUnitsLookup.vue';
import OObjectsLookup from 'assets.vue.components.ObjectLookup.vue';
import ODataLookup from 'o365.vue.components.DataLookup.vue';
import OSelect from 'o365.vue.components.Select.vue';

//const ODataLookup = useAsyncComponent('o365.vue.components.DataLookup.vue');

//const OrgUnitsLookup = useAsyncComponent('o365.vue.components.OrgUnitsLookup.vue');
//const ODataLookup = useAsyncComponent('o365.vue.components.DataLookup.vue');


const props = defineProps<{
    modelValue: any,
    row: DataItemModel, 
    config: {
        dataType: 'text' | 'number' | 'bool' | 'datetime' | 'date',
    },
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: any): void
}>();

const computedValue =  computed({
    get() { return props.modelValue},
    set(value) { emit('update:modelValue', value); }
});

const localMultiSelectValue = computed({
    get() { return props.modelValue?.split(',')},
    set(value) { 
        const newValue = value?.join(',');
        computedValue.value = newValue;
    }
})

function updateBitValue(pEvent) {
    const value = pEvent.target.checked ? 1 : 0;
    computedValue.value = value;
}

// TODO: Check if there's a better way to pass editable value that is not an object to a slot
const wrapper = {}; // Horrible way to pass editable value, but it works...
Object.defineProperty(wrapper, 'value', {
    get() { return computedValue.value },
    set(val) {computedValue.value = val}
});

const editorItem = ref(new PropertiesEditor(props, (pValue: any) => computedValue.value = pValue));
editorItem.value.initialize();
</script>